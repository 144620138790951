import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { Section } from '~/components/elements/Section';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import gatsbyPluginImage from 'gatsby-plugin-image';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function DefaultPage({ data: { wpPage, wp } }) {
  let headerColor = 'default';
  if (wpPage && wpPage.postTypePagePreview?.headerColor) headerColor = wpPage.postTypePagePreview?.headerColor;
  let showTopBar = false;
  if (wpPage && wpPage.postTypePagePreview?.showTopBar) showTopBar = true;
  let hideFooter = false;
  if (wpPage && wpPage.postTypePagePreview?.hideFooter) hideFooter = true;
  let headerType = false;
  if (wpPage && wpPage.postTypePagePreview?.headerType) headerType = wpPage.postTypePagePreview?.headerType;
  let ogImage = '';
  if (wpPage && wpPage.seo.opengraphImage) {
    // @todo CHANGE THIS
    ogImage = 'https://mosaic.and.together.agency' + wpPage.seo.opengraphImage.localFile.publicURL;
  }
  return (
    <Layout
      wp={wp}
      page={wpPage}
      headerColor={headerColor}
      showTopBar={showTopBar}
      hideFooter={hideFooter}
      headerType={headerType}
    >
      {wpPage && (
        <Seo
          post={wpPage}
          meta={[
            {
              name: 'og:image',
              content: ogImage,
            },
          ]}
        />
      )}
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections_Section_Components_"
        sections={wpPage?.postTypePage?.sections}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
